<div class="lead-banner">
    <div class="header">
        <button class="close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content class="lead-content">

        <my-portal-hero-text [leadData]="leadData"></my-portal-hero-text>

        <div class="form-container">
            <my-portal-campaign-form [leadData]="leadData"></my-portal-campaign-form>
        </div>
    </div>
</div>