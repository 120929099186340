import { Component, OnInit } from '@angular/core';
import { InitializerService } from 'projects/new-portal/src/app/services/initializer.service';
import { environment } from 'projects/new-portal/src/environments/environment';

@Component({
  selector: 'my-portal-simplified-header',
  templateUrl: './simplified-header.component.html',
  styleUrls: ['./simplified-header.component.scss']
})
export class SimplifiedHeaderComponent implements OnInit {
  data: any;
  isSafari: any = this.initializerService.isSafari();
  imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
  imgServerUrlMaxQuality : any;




  constructor(private initializerService: InitializerService) { }

  ngOnInit(): void {
    this.imgServerUrlMaxQuality = this.initializerService.setImageQuality(environment.IMAGES_END_URL_MAX_QUALITY);
    this.initializerService.currentData
    .subscribe((data) => {
        if (data) {
            this.data = data;
        }
    }).unsubscribe();
  }

}
