
<div *ngIf="packageList">
    <div class="row no-gutters packages mb-5" *ngFor="let package of packageList?.items; let i = index">
        <ng-container *ngIf="package?.offer">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <figure [ngClass]="{'loading': load }" class="package">
                    <div class="discount" *ngIf="package?.offer?.discount">{{ package.offer.discount }}%</div>
                    <img
                        src="{{imgServer + 'width=' + 555 + imgServerUrlEnd +  package.image_cover}}"
                        class="img-fluid">
                </figure>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 content-package">
                <div class="content-box">
                    <h6 [ngClass]="{'loading': load }">{{package.title}}</h6>
                    <span>De {{ package?.offer?.checkin | date: 'dd.MM.yyyy' }} à {{ package?.offer?.checkout | date: 'dd.MM.yyyy' }}</span>
                    <div [ngClass]="{'my-2': load }">
                        <p [ngClass]="{'loading': load }" [innerHTML]="package.description"></p>
                    </div>
                </div>
                <my-portal-counter [type]="true" *ngIf="package?.count" [date]="package.expiration_at"></my-portal-counter>
                <div class="footer">
                    <div class="prices" *ngIf="!load">
                        <div class="lower-price" *ngIf="package?.offer?.discount">De: {{ package?.offer?.price.original_price | currency }}</div>
                        <div class="price">{{ package?.offer?.discount !== 0 ? 'Por:' : 'A partir de:'}} <br *ngIf="package?.offer?.discount === 0"> {{ package?.offer?.price?.price | currency }}</div>
                    </div>
    
                    <div class="payment" *ngIf="package?.max_parcels && package?.max_parcels > 1">
                        parcelamos em até
                        <div>{{package?.max_parcels}}x no cartão de crédito</div>
                    </div>
    
                    <a [routerLink]="['conteudo', package.slug, package.id]">
                        <button>
                            reservar agora
                        </button>
                    </a>
                </div>
    
            </div>
        </ng-container>
    </div>
</div>


