import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'my-portal-hero-text',
    templateUrl: './hero-text.component.html',
    styleUrls: ['./hero-text.component.scss'],
})
export class HeroTextComponent implements OnInit {
    @Input() leadData: any;

    constructor() {}

    ngOnInit(): void {}
}
