import { Component, Input, OnInit } from '@angular/core';
import { InitializerService } from '../../../services/initializer.service';
import { AppDataService } from '../../../services/app-data.service';
import { DocumentService } from '../../../services/document.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LeadBannerComponent } from '../../../shared/campaigns/components/lead-banner/lead-banner.component';

interface IAttrs {
    async?: boolean;
    src?: string;
    defer?: boolean;
    type?: string;
    charset?: string;
    crossOrigin?: string;
    event?: string;
    htmlFor?: string;
    integrity?: string;
    noModule?: boolean;
    referrerPolicy?: string;
    text?: string;
}

type Attr = keyof IAttrs;

interface IScriptData {
    attrs?: IAttrs;
    data?: {
        [key: string]: string;
    };
    body?: string;
}

interface IFooterData {
    json_scripts: IScriptData[]
}[]

@Component({
    selector: 'my-portal-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    private whatsAppInfoSubscription$!: Subscription;
    public leadData: any[] = [];
    public isLoadingLeads = true;

    whatsappInfoNumber: any = {}; 
    data: any;
    footerData: any;
    urlMap: any = null;
    showBoxCookies: boolean = false;
    initalPage: boolean = false;
    loadMap: boolean = false;

    @Input() info: any

    constructor(private initializerService: InitializerService,
        private appData: AppDataService,
        private documentService: DocumentService,
        private activatedRouter: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router,
        private location: Location) {}


    ngOnInit(): void {
        this.initializerService.currentData
            .subscribe((data) => {
                if (data) {
                    this.data = data;
                    this.appData.getDataModule('footer')
                        .subscribe((response: any) => {
                            this.footerData = response.data;
                            this.initializerService.changeFooterDataService(this.footerData);
                            this.urlMap = (this.footerData.length === 1) ? this.footerData[0].location_map : null;
                            this._updateDataFooter(this.footerData);
                            this.loadMap = true;
                            this.onRequestCreateDynamicScript(this.footerData);
                        });
                }

            });

        this.initializerService.currentControlCookie
            .subscribe((data) => {
                if (data) {
                    this.showBoxCookies = data.showBoxCookie;
                }
            }
            );
        
        this.verifyNumberWhatsApp();

        this.whatsAppInfoSubscription$ = this.initializerService.currentWhatsAppInfo.subscribe((whatsAppInfo: any) => {
            this.whatsappInfoNumber = whatsAppInfo;
            this.verifyNumberWhatsApp(whatsAppInfo);
        })

        this.verifyCookies();
        this.getLeads();
    }

    verifyNumberWhatsApp(genericPage?: any) {
        if (this.info?.whatsapp?.active) {
            this.whatsappInfoNumber = !!this.info?.whatsapp?.active && !!genericPage?.number;
            this.whatsappInfoNumber = this.whatsappInfoNumber == Boolean(genericPage) ? true : false;
        }else{
            this.whatsappInfoNumber = false;
        }
    }

    _updateDataFooter(data: any): void {
        const footer = {
            phone: data[0].phone,
            whatsapp: data[0].whatsapp,
            email: data[0].email,
            location_map: data[0].location_map,
            address: data[0].address,
            cep: data[0].cep,
            city: data[0].city,
            state: data[0].state,
            social_network: data[0].social_network,
            district: (data[0].district) ? data[0].district : null,
            tagManager: this.info.google_tag_manager
        };

        this.initializerService.changeFooterData(footer);
        this.documentService.getWindow()?.sessionStorage.setItem('footer',
            JSON.stringify(footer));
    }

    verifyCookies(): void {
        let cookie = document.cookie.split(';');
        let cookieActived = false;
        for (let i = 0; i < cookie.length; i++) {
            if (cookie[i].indexOf('CookieActived') !== -1) {
                cookieActived = true;
            }
        }

        if (cookieActived) {
            this.showBoxCookies = false;
        } else {
            this.showBoxCookies = true;
        }
    }

    isBooleanAttr(attr: string | boolean | undefined): string {
        return typeof attr === "boolean" ? "" : attr as string;
    };

    createScript(scriptData: IScriptData): void {
        const scriptTag: HTMLScriptElement = document.createElement("script");

        if (scriptData.attrs) {
            let attr: Attr;
            for (attr in scriptData.attrs) {
                if (Boolean(scriptData.attrs[attr])) {
                    scriptTag.setAttribute(attr, this.isBooleanAttr(scriptData.attrs[attr]));
                }
            }
        }

        if (scriptData.data) {
            let data: string;
            for (data in scriptData.data) {
                if (Boolean(scriptData.data[data])) {
                    scriptTag.setAttribute(data, scriptData.data[data]);
                }
            }
        }

        if (scriptData.body) {
            scriptTag.innerHTML = scriptData.body;
        }

        document.body.append(scriptTag);
    };

    onRequestCreateDynamicScript(scriptData: IFooterData[]): void {
        for (const establishmentDataFooter of scriptData) {
            if (establishmentDataFooter.json_scripts.length) {
                for (const scriptItem of establishmentDataFooter.json_scripts) {
                    this.createScript(scriptItem);
                }
            }
        }
    }

    private getLeads() {
        this.appData
            .getLeads()
            .pipe(finalize(() => (this.isLoadingLeads = false)))
            .subscribe((response: any) => {
                this.leadData = response.data;
                this.verifyIfShowLeadBanner();
            });
    }

    private verifyIfShowLeadBanner() {
        const firstFloatLead = this.getFirstFloatLeadBanner();
        const firstScrollFloatLead = this.getFirstScrollFloatLeadBanner();
        const isOpportunity = this.router.url.includes('?uuid_oportunidade');
        
        if (!isOpportunity) {
            if (firstFloatLead) {
                this.createMouseLeaveListener(firstFloatLead);
            }
    
            if (firstScrollFloatLead) {
                this.createScrollListener(firstScrollFloatLead);
            }
        }
    }

    private createScrollListener(firstFloatLead: any): void {
        if (!this.documentService.getWindow()?.sessionStorage.getItem('isScrollBannerViewed')) {
            const scrollListener = (event: any) => {
                const isOpenedBanner = this.documentService.getWindow()?.sessionStorage.getItem('isScrollBannerViewed') || false;
                const progress = (document.documentElement.scrollTop / ( document.body.scrollHeight - window.innerHeight ) ) * 100;

                if (progress >= firstFloatLead.scroll_percent && !isOpenedBanner) {
                    const leadBannerDialogRef = this.dialog.open(LeadBannerComponent, {
                        panelClass: ['dialog-not-spacing', 'with-radius'],
                        width: '80vw',
                        maxWidth: '1024px',
                        data: firstFloatLead,
                    });

                    leadBannerDialogRef.afterClosed().subscribe(() => {
                        this.documentService.getWindow()?.sessionStorage.setItem('isScrollBannerViewed', '1');

                        document.removeEventListener('scroll', scrollListener);
                    });
                }
            };

            document.addEventListener('scroll', scrollListener);
        }
    }

    private createMouseLeaveListener(firstFloatLead: any): void {
        const isMobile = this.initializerService.isMobileDevice();

        if (!this.documentService.getWindow()?.sessionStorage.getItem('isBannerViewed') && !isMobile) {
            const mouseLeaveListener = (event: any) => {
                const isOpenedBanner = this.documentService.getWindow()?.sessionStorage.getItem('isBannerViewed') || false;

                if (event.clientY < 0 && this.dialog.openDialogs.length === 0 && !isOpenedBanner) {
                    const leadBannerDialogRef = this.dialog.open(LeadBannerComponent, {
                        panelClass: ['dialog-not-spacing', 'with-radius'],
                        width: '80vw',
                        maxWidth: '1024px',
                        data: firstFloatLead,
                    });

                    leadBannerDialogRef.afterClosed().subscribe(() => {
                        this.documentService.getWindow()?.sessionStorage.setItem('isBannerViewed', '1');

                        document.removeEventListener('mouseleave', mouseLeaveListener);
                    });
                }
            };

            document.addEventListener('mouseleave', mouseLeaveListener);
        }
    }

    private getFirstFloatLeadBanner() {
        return this.leadData.find((lead) => lead.type.id == 2);
    }

    private getFirstScrollFloatLeadBanner() {
        return this.leadData.find((lead) => lead.type.id == 3);
    }

    ngOnDestroy(): void {

        if (this.whatsAppInfoSubscription$) {
            this.whatsAppInfoSubscription$.unsubscribe();
        }
    }
}

