<header>
    <div class="logo">
        <a href="/">
            <figure>
                <img class="img-fluid"
                    src="{{ data?.logo ? imgServer + 'width=' + 157 + imgServerUrlMaxQuality + data?.logo : 'https://images.focomultimidia.com/curl/motor_reserva/images/logo.png' }}"
                    width="157" height="auto" alt="logo">
            </figure>
        </a>
    </div>
</header>

<div class="message">
    <h1>Orçamento realizado com sucesso. <span> Aguardando pagamento!</span></h1>
</div>