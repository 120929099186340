import { Component, OnInit, Input } from '@angular/core';
import { Swiper, SwiperOptions } from 'swiper';
import { environment } from './../../../../../environments/environment';
import { InitializerService } from './../../../../services/initializer.service';

@Component({
    selector: 'my-portal-slideshow',
    templateUrl: './slideshow.component.html',
    styleUrls: ['./slideshow.component.scss'],
})
export class SlideshowComponent implements OnInit {
    @Input() dataHeader: any = [];
    @Input() type: any;
    @Input() typeMenu: any;
    @Input() effectType: any;
    @Input() typeEstablishment: any;
    @Input() typeHotel: any;
    isMobile: boolean = this.initializerService.isMobileDevice();

    load: boolean = true;
    config: SwiperOptions = {
        autoplay: true,
        loop: true,
        speed: 1200,
        autoHeight: true,
        cubeEffect: {
            slideShadows: false,
            shadow: false,
        },
        coverflowEffect: {
            slideShadows: false,
        },
        navigation: {
            nextEl: '.swiper-button-next-slide',
            prevEl: '.swiper-button-prev-slide',
        },
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    dataInfo: any;
    imgServerUrlNinetyQuality: any;

    constructor(private initializerService: InitializerService) {}

    ngOnInit(): void {
        this.imgServerUrlNinetyQuality = this.initializerService.setImageQuality(environment.IMAGES_END_URL_NINETY_QUALITY);

        this.load = false;
        this.config.effect = this.effectType?.name ? this.effectType?.name : 'fade';
        this.initializerService.currentData.subscribe((data) => {
            this.dataInfo = data;
        });
    }

    onImageLoad(event: Event, index: number) {
        const imgElement = event.target as HTMLImageElement;
        const imageHeight = imgElement.naturalHeight;

        if (imageHeight > 500) {
            this.type = 3;
        } else {
            this.type = 1;
        }
    }
}
