<div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <swiper [config]="config" [initialize]="initialize">
            <div class="swiper-wrapper">
                <div *ngFor="let package of packages" class="swiper-slide">
                    <figure [ngClass]="{'loading': load }" class="package">
                        <div *ngIf="package.discount" class="discount">
                            {{package.discount}}%
                        </div>
                        <img class="img-fluid w-100" src="{{imgServer + 'width=' + 350 + imgServerUrlEnd + package.image}}" width="350" height="auto" alt="imagem">
                    </figure>
                    <div class="content-package p-3 border border-top-0">
                        <h6 [ngClass]="{'loading': load }" class="mt-2">{{package.title}}</h6>
                        <div [ngClass]="{'my-2': load }">
                            <p [ngClass]="{'loading': load }" [innerHTML]="package.description"></p>
                        </div>
                        <div *ngIf="!load" class="price package" [ngClass]="{'no-border': !package.count}">
                            <div>
                                <div class="old" *ngIf="package.discount">De: {{ package.lowest_rate.old_price | currency }}</div>
                                <div class="price">{{ package.discount !== 0 ? 'Por:' : 'A partir de:'}} <br *ngIf="package.discount === 0"> {{ package.lowest_rate.price | currency }}</div>

                            </div>
                            <div class="text-right" *ngIf="package.max_parcels && package.max_parcels > 1">
                                <div class="description">Parcelamos em até</div>
                                <div class="total">{{package.max_parcels}}x no cartão de crédito</div>
                            </div>
                        </div>
                        <my-portal-counter *ngIf="package.count" [date]="package.final_date"></my-portal-counter>
                        <a [routerLink]="hotelId ? ['/campanhas/conteudo', package.slug, package.id, hotelId] : ['campanhas/conteudo', package.slug, package.id]">
                            <button *ngIf="!load" [ngClass]="{'loading': load }">
                                reservar agora
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <!-- Add Pagination -->
            <div class="pagination-accommodation"></div>
        </swiper>
    </div>
</div>
