export const environment = {
    production: false,
    API_ENDPOINT: 'https://homologacao-api-dev.soufoco.com/',
    PASS_ENDPOINT: 'https://api.focopass.com.br/',
    // IMAGES_ENDPOINT: 'https://images.focomultimidia.com/curl/',
    //IMAGES_ENDPOINT: 'https://images3.motor-reserva.com.br/curl/',
    //IMAGES_ENDPOINT: 'https://images3.motor-reserva.com.br/cdn-cgi/image/',
    IMAGES_ENDPOINT: 'https://images3.motor-reserva.com.br/cdn-cgi/image/fit=scale-down,format=webp,',
    IMAGES_ENDPOINT_SAFARI: 'https://images3.motor-reserva.com.br/cdn-cgi/image/fit=scale-down,',
    IMAGES_END_URL: ',quality=75/curl/',
    IMAGES_END_URL_NINETY_QUALITY: ',quality=90/curl/',
    IMAGES_END_URL_MAX_QUALITY: ',quality=100/curl/',
    OTAS_ENDPOINT: 'https://otas.focomultimidia.com/',
    BRASIL_API: 'https://brasilapi.com.br/api/',
    DATEPICKER_JSON_ENDPOINT: 'https://swb01fmu.focomultimidia.com/curl/motor_reserva/json/datepicker',
    DATEPICKER_REQUEST_ENDPOINT: 'https://homologacao-foco-serverless-dev.soufoco.com.br/datepicker',
    EXTRANET_CRS_ENDPOINT: 'https://homologacao-extranet-api.focohoteis.com.br/api/v1',
};
