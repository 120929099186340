import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { finalize } from 'rxjs/operators';
import { DefaultSnackBarComponent } from '../../../default-snack-bar/default-snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'my-portal-campaign-form',
    templateUrl: './campaign-form.component.html',
    styleUrls: ['./campaign-form.component.scss'],
})
export class CampaignFormComponent implements OnInit {
    @Input() leadData: any;

    public isLoadingForm: boolean = true;
    public isSendingLead: boolean = false;

    public selectedDateTravel: string = '';
    public selectedDateEvent: string = '';

    public selectedGuests: number = 1;
    public selectedReason: any = null;

    public campaignForm!: any;
    phone: any = {sigla: 'br', ddi: '55'};

    @ViewChild('pickerDateTravel') pickerDateTravel!: MatDateRangePicker<Date>;
    @ViewChild('pickerDateEvent') pickerDateEvent!: MatDateRangePicker<Date>;

    public reasonForTheTripList: any[] = [
        { id: 'leisure', label: 'Lazer' },
        { id: 'business', label: 'Negócios' },
        { id: 'conference', label: 'Congresso' },
        { id: 'relatives', label: 'Parentes' },
        { id: 'studies', label: 'Estudos' },
        { id: 'religion', label: 'Religião' },
        { id: 'health', label: 'Saúde' },
        { id: 'shopping', label: 'Compras' },
        { id: 'other', label: 'Outro' },
    ];

    constructor(
        private formBuilder: FormBuilder,
        private appData: AppDataService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.campaignForm = this.formBuilder.group({
            name: new FormControl(null, Validators.required),
            whatsapp: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, Validators.email]),
            observation: new FormControl(null),
        });

        if (this.leadData.inputs.travel_period) {
            this.campaignForm.addControl(
                'travel_period',
                this.formBuilder.group({
                    start: new FormControl(null, Validators.required),
                    end: new FormControl(null, Validators.required),
                })
            );
        }

        if (this.leadData.inputs.event_period) {
            this.campaignForm.addControl(
                'event_period',
                this.formBuilder.group({
                    start: new FormControl(null, Validators.required),
                    end: new FormControl(null, Validators.required),
                })
            );
        }

        if (this.leadData.inputs.qtd_guest) {
            this.campaignForm.addControl(
                'guests',
                this.formBuilder.group({
                    pax: new FormControl(1, Validators.required),
                    chd: new FormControl([]),
                })
            );
        }

        if (this.leadData.inputs.travel_reason) {
            this.campaignForm.addControl('travel_reason', new FormControl(null, Validators.required));
        }

        this.isLoadingForm = false;
    }

    public openPickerDateTravel(): void {
        this.pickerDateTravel.open();
    }

    public openPickerDateEvent(): void {
        this.pickerDateEvent.open();
    }

    public dateSelectedTravel(startDate: any, endDate: any): void {
        this.selectedDateTravel = this.formatDateRange({ start: startDate?.value, end: endDate?.value });
    }

    public dateSelectedEvent(startDate: any, endDate: any): void {
        this.selectedDateEvent = this.formatDateRange({ start: startDate?.value, end: endDate?.value });
    }

    private formatDateRange(selection: any): string {
        if (!selection) return '';

        const startDate = selection.start;
        const endDate = selection.end;

        if (!startDate || !endDate) return '';

        const formattedStartDate = moment(startDate, 'DD/MM/YYYY').format('DD MMM.');
        const formattedEndDate = moment(endDate, 'DD/MM/YYYY').format('DD MMM.');

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    public onCountryChange(country: any) {
        this.phone.sigla = country.iso2;
        this.phone.ddi = country.dialCode;
    }

    public selectGuests(guestQuantity: number) {
        this.selectedGuests = guestQuantity;
        this.campaignForm.get('guests')?.setValue({ pax: guestQuantity, chd: [] });
    }

    public selectReason(reason: any): void {
        this.selectedReason = reason.label;
        this.campaignForm.get('travel_reason')?.setValue(reason.id);
    }

    public onDateTravelSelection(selection: any): void {
        this.selectedDateTravel = this.formatDateRange(selection);
        this.campaignForm.get('travel_period')?.setValue(selection);
    }

    public onDateEventSelection(selection: any): void {
        this.selectedDateEvent = this.formatDateRange(selection);
        this.campaignForm.get('event_period')?.setValue(selection);
    }

    public onSubmit(): void {
        if (this.campaignForm.invalid) {
            this.campaignForm.markAllAsTouched();

            return;
        }

        let formPayload = Object.assign({}, this.campaignForm.value);
        formPayload.whatsapp = this.phone.ddi + formPayload.whatsapp;

        if (formPayload.travel_period) {
            formPayload.travel_period.start = moment(formPayload.travel_period.start).format('YYYY-MM-DD');
            formPayload.travel_period.end = moment(formPayload.travel_period.end).format('YYYY-MM-DD');
        }

        if (formPayload.event_period) {
            formPayload.event_period.start = moment(formPayload.event_period.start).format('YYYY-MM-DD');
            formPayload.event_period.end = moment(formPayload.event_period.end).format('YYYY-MM-DD');
        }

        this.isSendingLead = true;
        this.sendLead(formPayload);
    }

    private sendLead(formPayload: any): void {
        this.appData
            .sendLead(formPayload)
            .pipe(finalize(() => (this.isSendingLead = false)))
            .subscribe(
                (response: any) => {
                    this.openSnackBar('Enviado com sucesso!');

                },
                (_) => {
                    this.openSnackBar('Houve um erro ao enviar os dados.')
                }
            );
    }

    public openSnackBar(message: string, type: string = 'success'): void {
        this.snackBar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message,
            },
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: type === 'success' ? 'success-snack-bar' : 'error-snack-bar',
            duration: 5000,
        });
    }
}
