<section class="mt-5 mb-5" id="genericPageList">
    <my-portal-inner-load *ngIf="load"></my-portal-inner-load>
    <div *ngIf="!load">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <my-portal-highlighter [module]="{title: landingPage?.title, description: landingPage?.subtitle}"></my-portal-highlighter>
            </div>
        </div>

        <my-portal-list-news *ngIf="!load && landingPage?.type?.id === 1" [news]="landingPage"></my-portal-list-news>
        <my-portal-list-promotions *ngIf="!load && landingPage?.type?.id === 2" [packageList]="landingPage"></my-portal-list-promotions>

        <div class="row" *ngIf="!load && landingPage?.type?.id === 3 && landingPage?.description">
            <div class="col mb-3">
                <p [innerHTML]="landingPage?.description | sanitizeHtml"></p>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-lg-3 generic-pages" *ngIf="!load && landingPage?.type?.id === 3">
            <ng-template #listGenericPages [ngTemplateOutlet]="listGenericPages"
                         *ngFor="let page of landingPage?.items">
                <a [routerLink]="['conteudo', page.slug, page.id]">
                    <div class="col mb-4">
                        <figure>
                            <img [src]="imgServer + 'width=' + 350 + imgServerUrlEnd + page.image_cover" class="img-fluid w-100">
                        </figure>
                        <div class="box-page">
                            <div>
                                <h4>{{ page.title }}</h4>
                                <p [innerText]="page.subtitle"></p>
                            </div>
                            <a [routerLink]="['conteudo', page.slug, page.id]">
                                <div class="box-button">
                                    <button>saiba mais</button>
                                </div>
                            </a>
                        </div>
                    </div>
                </a>
            </ng-template>
        </div>
    </div>
</section>
