import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from './document.service';

@Injectable({
    providedIn: 'root'
})
export class InitializerService {

    private sidenav!: MatSidenav;
    private sharedData = new BehaviorSubject(null);
    currentData = this.sharedData.asObservable();

    private controlFormSearch = new BehaviorSubject(false);
    currentControlFormSearch = this.controlFormSearch.asObservable();

    private controlAuthGuard = new BehaviorSubject(false);
    currentControlAuthGuard = this.controlAuthGuard.asObservable();

    private controlLocalSelected = new BehaviorSubject({});
    currentControlLocalSelected = this.controlLocalSelected.asObservable();

    private sharedFooter = new BehaviorSubject(null);
    currentFooterData = this.sharedFooter.asObservable();

    private controlCookie = new BehaviorSubject({
        showBoxCookie: false,
    });

    private getFooterData = new BehaviorSubject<any>(null);
    currentGetFooterData = this.getFooterData.asObservable();

    private uncheckPolicies = new BehaviorSubject<Record<string, string | number | null> | null>(null);
    currentUncheckPolicies = this.uncheckPolicies.asObservable();

    currentControlCookie = this.controlCookie.asObservable();
    uuid: any = this.documentService.getWindow()?.localStorage.getItem('uuid');

    private whatsAppInfo = new BehaviorSubject(null);
    currentWhatsAppInfo = this.whatsAppInfo.asObservable();

    constructor(
        private http: HttpClient,
        private documentService: DocumentService
    ) { }

    getInitializer(params?: any): Observable<object> {
        const pessoa_id = this.documentService.getWindow()?.sessionStorage?.getItem('person');
        let obj = new HttpParams();
        let requestHeaders = new HttpHeaders();

        if (this.uuid) {
            requestHeaders = requestHeaders.append('uuid', this.uuid);
        }

        if (params) {
            if (params.personId || pessoa_id) {
                obj = obj.append('pessoa_id', params.personId ?? pessoa_id);
            }

            if (params.temporaryId) {
                obj = obj.append('temporary_id', params.temporaryId);
            }            
        }


        return this.http.get(`${environment.API_ENDPOINT}info`, {
            params: obj,
            headers: requestHeaders
        });
    }

    changeInitializerData(data: any): void {
        this.sharedData.next(data);
    }

    changeFooterData(data: any): void {
        this.sharedFooter.next(data);
    }

    changeFooterDataService(data: any): void {
        this.getFooterData.next(data);
    }

    changeControlFormSearch(value: any): void {
        const gallery: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-tab-body.mat-tab-body-active');
        if (gallery) {
            gallery.style.zIndex = 0;
        }
        this.controlFormSearch.next(value);
    }

    changeControlAuth(value: any): void {
        this.controlAuthGuard.next(value);
    }

    changeControlLocalSelected(value: any): void {
        this.controlLocalSelected.next(value);
    }

    changeUncheckPolicies(value: Record<string, string | number | null>): void {
        this.uncheckPolicies.next({
            removeAt: value.removeAt,
            status: value.status,
            personId: value.personId
        });
    }

    changeControlCookie(value: any): void {
        this.controlCookie.next(value);
    }

    isMobileDevice(): boolean {
        let check = false;
        ((a) => {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor);
        return check;
    }

    isSafari(): any {
        var sBrowser, sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome";
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari";
        } else if (sUsrAg.indexOf("Opera") > -1) {
            sBrowser = "Opera";
        } else if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";
        } else if (sUsrAg.indexOf("MSIE") > -1) {
            sBrowser = "Microsoft Internet Explorer";
        }

        if (sBrowser == "Apple Safari") {
            return true;
        }
    }

    generateSlug(title: string): string {
        let slug = title.toLowerCase().trim();
        slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
        slug = slug.replace(/[\s-]+/g, '-');

        return slug;
    }

    setImageQuality(staticImageQuality: string): string {
        let imageResolution = staticImageQuality;

        this.currentData.subscribe((data: any) => {
            if (data?.image_resolution) {
                imageResolution = `,quality=${data?.image_resolution}/curl/`
            }
        });

        return imageResolution;
    }

    public setSidenav(sidenav: MatSidenav): void {
        this.sidenav = sidenav;
    }

    public open(): Promise<MatDrawerToggleResult> {
        return this.sidenav.open();
    }

    public close(): Promise<MatDrawerToggleResult> {
        return this.sidenav.close();
    }

    public toggle(): Promise<MatDrawerToggleResult> {
        return this.sidenav.toggle();
    }

    public deepCopy<T>(data: T): T {
        return JSON.parse(JSON.stringify(data));
    }

    changeCurrentWhatsApp(whatsAppInfo: any): void {
        this.whatsAppInfo.next(whatsAppInfo);
    }
}
