import { PromotionComponent } from './features/packages/promotion/promotion.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from './core/layout/main-layout/main-layout.component';
import {GenericPagesModule} from './features/generic-pages/generic-pages.module';
import {AvailableHotelItemComponent} from './shared/available-hotel-item/available-hotel-item.component';
import { DetailHotelComponent } from './features/hotels/detail/detail.component';
import { GalleryItemComponent } from './features/gallery/gallery-item/gallery-item.component';
import {GenericPagesListComponent} from './features/generic-pages/list/list.component';
import { RestrictedAreaClientComponent } from './shared/restricted-area-client/restricted-area-client.component';
import {DestinationsModule} from './features/destinations/destinations.module';
import { RecoverPasswordComponent } from './features/restricted-area/recover-password/recover-password.component';
import {ToursComponent} from './features/receptive/tours/tours.component';
import { GuardService } from './services/guard.service';
import {TransfersComponent} from './features/receptive/transfers/transfers.component';
import {TicketsComponent} from './features/receptive/tickets/tickets.component';
import { VideoPlayerComponent } from './shared/video-player/video-player.component';
import {PrecheckinComponent} from './shared/precheckin/precheckin.component';
import { PrivacyPolicyLandingPageComponent } from './shared/privacy-policy-landing-page/privacy-policy-landing-page.component';
import { FaqComponent } from './shared/faq/faq.component';
import { DetailComponent } from './features/generic-pages/detail/detail.component';

const routes: Routes = [
    {path: '', component: MainLayoutComponent, pathMatch: 'full'},
    {path: 'sobre', loadChildren: () => import('./features/about/about.module').then(m => m.AboutModule)},
    {path: 'acomodacao', loadChildren: () => import('./features/accommodations/accommodations.module').then(m => m.AccommodationsModule)},
    {path: 'packages', loadChildren: () => import('./features/packages/packages.module').then(m => m.PackagesModule)},
    {path: 'promocao/:slug/:id', component: PromotionComponent},
    {path: 'promocao/:slug/:id/:hotelId', component: PromotionComponent},
    {path: 'depoimento', loadChildren: () => import('./features/testimonies/testimonies.module').then(m => m.TestimoniesModule)},
    {path: 'gallery', loadChildren: () => import('./features/gallery/gallery.module').then(m => m.GalleryModule)},
    {path: 'videos', component: VideoPlayerComponent},
    {path: 'foto/:slug/:galleryId', component: GalleryItemComponent},
    {path: 'hotel/:title/:id', component: DetailHotelComponent },
    {path: 'hotels/:checkin/:checkout/:rooms', component: AvailableHotelItemComponent},
    {path: 'hotels/:checkin/:checkout/:rooms/:slug/:destinationId', component: AvailableHotelItemComponent},
    {path: 'hotels/:checkin/:checkout/:rooms/:pessoaId', component: AvailableHotelItemComponent},
    {path: 'sale', loadChildren: () => import('./features/shop/shop.module').then(m => m.ShopModule)},
    {path: 'contato', loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule)},
    
    {
        path: 'search/:checkin/:checkout/:rooms',
        loadChildren: () => import('./features/tariffs/tariffs.module').then(m => m.TariffsModule)
    },
    {
        path: 'search',
        loadChildren: () => import('./features/tariffs/tariffs.module').then(m => m.TariffsModule)
    },
    {
        path: 'search/:checkin/:checkout/:rooms/:id',
        loadChildren: () => import('./features/tariffs/tariffs.module').then(m => m.TariffsModule)
    },
    {
        path: 'search/:checkin/:checkout/:rooms/:id/:accommodationId',
        loadChildren: () => import('./features/tariffs/tariffs.module').then(m => m.TariffsModule)
    },
    {
        path: 'conteudo/:title/:id',
        loadChildren: () => import('./features/generic-pages/generic-pages.module').then(m => m.GenericPagesModule)
    },
    {
        path: 'categoria/:title/:id',
        component: GenericPagesListComponent
    },
    {
        path: 'noticias',
        component: GenericPagesListComponent
    },
    {
        path: 'noticias/conteudo/:slug/:id',
        component: DetailComponent,
    },
    {
        path: 'paginas-genericas',
        component: GenericPagesListComponent
    },
    {
        path: 'paginas-genericas/conteudo/:slug/:id',
        component: DetailComponent,
    },
    {
        path: 'campanhas',
        component: GenericPagesListComponent
    },
    {
        path: 'campanhas/conteudo/:slug/:id',
        component: PromotionComponent,
    },
    {
        path: 'tags/:slug/:id',
        component: GenericPagesListComponent,
    },
    {
        path: 'area-restrita',
        loadChildren: () => import('./features/restricted-area/restricted-area.module').then(m => m.RestrictedAreaModule),
        canActivate: [GuardService]
    },
    {
        path: 'area-restrita/recuperar-senha/:type/:token',
        component: RecoverPasswordComponent
    },
    {
        path: 'passeio/:title/:id',
        component: ToursComponent
    },
    {
        path: 'transfer/:title/:id',
        component: TransfersComponent
    },
    {
        path: 'ingresso/:title/:id',
        component: TicketsComponent
    },
    {
        path: 'pre-checkin',
        component: PrecheckinComponent
    },
    {
        path: 'politica-de-privacidade',
        component: PrivacyPolicyLandingPageComponent
    },
    {
        path: 'faq',
        component: FaqComponent
    },
    // {
    //     path: 'destino',
    //     loadChildren: () => import('./features/destinations/destinations.module').then(m => m.DestinationsModule)
    // },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        relativeLinkResolution: 'legacy',
        anchorScrolling: 'enabled',
        enableTracing: false,
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
