import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from 'projects/new-portal/src/app/services/document.service';

@Component({
    selector: 'my-portal-lead-banner',
    templateUrl: './lead-banner.component.html',
    styleUrls: ['./lead-banner.component.scss'],
})
export class LeadBannerComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public leadData: any,
    ) {}

    ngOnInit(): void {}
}
