import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppDataService} from '../../../services/app-data.service';
import {ActivatedRoute} from '@angular/router';
import { environment } from './../../../../environments/environment';
import { InitializerService } from '../../../services/initializer.service';
import { concatMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'my-portal-generic-pages-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class GenericPagesListComponent implements OnInit, OnDestroy {

    routes: any = {
        noticias: 'news',
        'paginas-genericas': 'generic-pages',
        campanhas: 'campaigns',
        tags: 'tags',
    }

    destroySubcription$: Subject<boolean> =  new Subject<boolean>();

    landingPage: any = null;
    load: boolean = true;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private appService: AppDataService,
                private activatedRoute: ActivatedRoute,
                private initializerService: InitializerService,) {
    }

    ngOnInit(): void {
        this.activatedRoute.url.pipe(
            concatMap(response => {
                if (this.routes[response[0].path] === 'tags') {
                    return this.appService.getDynamicPages(this.routes[response[0].path], null, response[2]?.path);
                } else {
                    return this.appService.getDynamicPages(this.routes[response[0].path], null, response[1]?.path);
                }
            }
        ),
        takeUntil(this.destroySubcription$),
        ).subscribe(response => {
            if (response.data) {
                this.landingPage = response.data;
                if (this.landingPage.type.id === 3 || this.landingPage.type.id === 2 || this.landingPage.type.id === 1) {
                    for (const item of this.landingPage.items) {
                        item.slug = this.appService.createSlugUrl(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                    }
                }
                this.load = false;
            }
        });

        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }

    ngOnDestroy(): void {
        this.destroySubcription$.next(true);
        this.destroySubcription$.complete();
    }

}
