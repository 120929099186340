<swiper [class.fullscreen]="type > 1" [class.floating-menu]="type != 1 && typeMenu == 1 && typeEstablishment == 6" [config]="config" [initialize]="dataHeader">
    <div class="swiper-wrapper">
        <figure
            *ngFor="let image of dataHeader; let i = index"
            class="swiper-slide figure-slide"
            [ngClass]="{ 'no-overlay': !dataInfo?.search_engine }">
            <a [href]="image.link" *ngIf="image.link" class="redirect-link" target="_blank">
                <img
                    class="img-fluid img-full"
                    src="{{ imgServer + 'width=' + 1920 + imgServerUrlNinetyQuality + image.img }}"
                    width="1920"
                    height="auto"
                    alt="slide imagem"
                />
            </a>
            <img
                (load)="onImageLoad($event, i)"
                class="img-fluid img-full"
                src="{{ imgServer + 'width=' + 1920 + imgServerUrlNinetyQuality + image.img }}"
                width="1920"
                height="auto"
                alt="slide imagem"
                *ngIf="!image.link"
            />
        </figure>
    </div>

    <div class="swiper-button-next-slide">
        <mat-icon>east</mat-icon>
    </div>
    <div class="swiper-button-prev-slide">
        <mat-icon>west</mat-icon>
    </div>
</swiper>
