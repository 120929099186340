<div class="row no-gutters packages" *ngFor="let package of packages; let i = index" [ngClass]="{'flex-row-reverse': i > 0}">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <figure [ngClass]="{'loading': load }" class="package">
            <div class="discount" *ngIf="package.discount">{{ package.discount }}%</div>
            <img
                src="{{imgServer + 'width=' + 560 + imgServerUrlEnd + package.image}}"
                class="img-fluid w-100" width="560" height="auto" alt="imagem">
        </figure>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 content-package">
        <div class="content-box">
            <h6 [ngClass]="{'loading': load }">{{package.title}}</h6>
            <span>De {{ package.lowest_rate.checkin | date: 'dd.MM.yyyy' }} à {{ package.lowest_rate.checkout | date: 'dd.MM.yyyy' }}</span>
            <div [ngClass]="{'my-2': load }">
                <p [ngClass]="{'loading': load }" [innerHTML]="package.description"></p>
            </div>
        </div>
<!--         max_parcels-->
        <my-portal-counter *ngIf="package.count" [type]="true" [date]="package.final_date"></my-portal-counter>
        <div class="footer">
            <div class="prices" *ngIf="!load">
                <div class="lower-price" *ngIf="package?.discount !== 0">De: {{ package.lowest_rate.old_price | currency }}</div>
                <div class="price">{{ package.discount !== 0 ? 'Por:' : 'A partir de:'}}<br *ngIf="package.discount === 0"> {{ package.lowest_rate.price | currency }}</div>
            </div>

            <div class="payment" *ngIf="package.max_parcels && package.max_parcels > 1">
                parcelamos em até
                <div>{{package.max_parcels}}x no cartão de crédito</div>
            </div>

            <a [routerLink]="hotelId ? ['/campanhas', 'conteudo', package.slug, package.id, hotelId] : ['campanhas', 'conteudo', package.slug, package.id]">
                <button>
                    saiba mais
                </button>
            </a>
        </div>

    </div>
</div>
