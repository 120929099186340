<section *ngIf="videos?.length" class="mt-5 mb-5 video-youtube">
    <my-portal-highlighter *ngIf="removeHighlighter" [module]="moduleData"></my-portal-highlighter>

    <div *ngIf="typeSearch < 6">
        <!-- VIDEO SEM TABS -->
        <div *ngIf="type?.type === 'single'">
            <div class="text-center video">
                <div *ngFor="let video of videos" class="mt-3">
                    <iframe loading="lazy" title="Vídeo" [src]="video.safeUrl" allowfullscreen frameborder="0" height="380" mozallowfullscreen
                            showinfo="1" webkitallowfullscreen width="100%"></iframe>
                    <h6 *ngIf="video.title" class="text-center mt-2">{{video.title}}</h6>
                    <p *ngIf="video.description" class="text-center">{{ video.description }}</p>
                </div>
            </div>
        </div>

        <!-- VIDEO COM TABS -->
        <div *ngIf="type?.type === 'tabs'">
            <div class="text-center video">
                <mat-tab-group mat-stretch-tabsBo>
                    <mat-tab *ngFor="let video of videos" [label]="video.title | uppercase">
                        <div class="mt-5">
                            <iframe loading="lazy" title="Vídeo" [src]="video.safeUrl" allowfullscreen frameborder="0" height="380"
                                    mozallowfullscreen showinfo="1" webkitallowfullscreen width="100%"></iframe>
                            <p *ngIf="video.description" class="text-center">{{ video.description }}</p>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <div *ngIf="typeSearch >= 6">
        <div class="text-center video">
            <mat-tab-group mat-stretch-tabsBo>
                <mat-tab *ngFor="let hotel of videos" [label]="hotel.hotel | uppercase">
                    <div class="container-fluid">
                        <div class="row">

                        </div>
                    </div>

                    <swiper [config]="config" [initialize]="initalizer" class="mt-5">
                        <div class="swiper-wrapper">
                            <ng-template #listVideosHotels *ngFor="let video of hotel?.videos"
                                         [ngTemplateOutlet]="listVideosHotels">
                                <div class="swiper-slide">
                                        <iframe loading="lazy" title="Vídeo" [src]="video.safeUrl" allowfullscreen frameborder="0"
                                                mozallowfullscreen showinfo="1" webkitallowfullscreen></iframe>
                                        <h6 *ngIf="video.title" class="text-center mt-2">{{video.title}}</h6>
                                        <p *ngIf="video.description" class="text-center">{{ video.description }}</p>
                                </div>
                            </ng-template>
                        </div>
                        <!-- Add Pagination -->
                        <div class="swiper-pagination pagination-accommodation pagination-video"></div>
                    </swiper>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

</section>

