import { environment } from './../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {AppDataService} from './../../../services/app-data.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {SwiperOptions} from 'swiper';
import {SeoService} from '../../../services/seo.service';
import { InitializerService } from '../../../services/initializer.service';
import { TagManagerService } from '../../../services/tag-manager.service';
import { PromotionDetail, PromotionOffer } from 'projects/new-portal/src/@types/tag-manager';
import { concatMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'my-portal-promotion',
    templateUrl: './promotion.component.html',
    styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit, OnDestroy {
    infoData: any;
    routes: any = {
        noticias: 'news',
        'paginas-genericas': 'generic-pages',
        campanhas: 'campaigns',
        tags: 'tags',
    }

    destroySubcription$: Subject<boolean> =  new Subject<boolean>();

    promotion: any;
    loading: boolean = true;
    hotelId = this.activatedRouter.snapshot.params.hotelId;

    config: SwiperOptions = {
        autoplay: true,
        effect: 'fade',
        loop: true,
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
            el: '.images-pagination',
            clickable: true,
        },
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private appData: AppDataService,
                private activatedRouter: ActivatedRoute,
                private seoService: SeoService,
                private router: Router,
                private initializerService: InitializerService,
                private tagManagerService: TagManagerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        setTimeout(() => {
            this.appData.scroll('promotionDetail');
        }, 25);

        const [, mainPath] = this.router.url.split('/')

        this.activatedRouter.url.pipe(
            concatMap(response => {
                return this.appData.getDynamicPages(this.routes[mainPath], 'content', response[3]?.path)
            }),
            takeUntil(this.destroySubcription$)
        ).subscribe(
            (response: any) => {
                this.promotion = response.data;
                const objSeo = {
                    seo: {
                        title: this.promotion.title,
                        description: this.promotion.subtitle,
                        keyWorks: null,
                        image: this.promotion.galeries.images[0]
                    }
                };
                this.seoService.updateSeo(objSeo);
                this.loading = false;
                this.tagManagerService.viewPromotion(response.data)
            },
        );

        this.initializerService.currentData.subscribe((response) => {
            this.infoData = response;
        }).unsubscribe();
    }

    public handleSeeOptions(promotion: PromotionDetail, offer: PromotionOffer): void {
        this.tagManagerService.viewOfferEvent(promotion, offer);
    }

    ngOnDestroy(): void {
        this.destroySubcription$.next(true);
        this.destroySubcription$.complete();
    }
}
