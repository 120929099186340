<ng-container *ngIf="!isLoadingLeads"></ng-container>

<ng-container *ngFor="let lead of leadData">
    <ng-container *ngIf="lead.type?.id === 1 || lead.type?.id === 4">
        <div class="campaigns-container">
            <ng-container *ngIf="!lead.image_url">
                <div class="campaign-item">
                    <my-portal-hero-text [leadData]="lead"></my-portal-hero-text>
                </div>
            </ng-container>

            <div class="inner-content">
                <div *ngIf="lead.image_url">
                    <my-portal-highlighter [module]="{ title: lead.title, description: lead.subtitle }"></my-portal-highlighter>
                </div>

                <div>
                    <my-portal-campaign-form [leadData]="lead"></my-portal-campaign-form>
                </div>
            </div>

            <ng-container *ngIf="lead.image_url">
                <div class="campaign-item image-container">
                    <img [src]="imgServer + 'width=' + 480 + imgServerUrlEnd + lead.image_url" class="img-fluid">
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-container>