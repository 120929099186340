<ng-container *ngIf="widget$ | async as campaign">
    <div class="card-campaign-container" [@moveCard]="moveState">
        <div class="card-campaign">
            <div class="card-campaign-header">
                <div class="header-box"></div>
                <h3 class="campaign-header-title">
                   {{ campaign.top_card }}
                </h3>
                <div class="header-box header-action" (click)="triggerAnimation()">
                    <i class="material-icons notranslate" *ngIf="moveState === 'original'">remove</i>
                    <i class="material-icons notranslate" *ngIf="moveState === 'moved'">add</i>
                </div>
            </div>

                <div class="campaign-image-box">
                    <img [src]="imgServer + 'width=' + 320 + imgServerUrlEnd + campaign.image_url" alt="Imagem da campanha">
                    <div class="discount-percentage" *ngIf="campaign?.parcentage > 0">{{campaign.parcentage}}% OFF</div>
                </div>
                <div class="card-campaign-description">
                    <h1 class="description-title">{{campaign.title}}</h1>
                    <div class="description" [innerHTML]="campaign.description"></div>
                </div>
                <div class="card-campaign-prices">
                    <ng-container *ngIf="campaign?.start_value && campaign?.end_value && campaign?.start_value !== campaign?.end_value">
                        <p class="original-price">De: <span>{{campaign.start_value | currency}}</span></p>
                        <p class="discounted-price"> 
                            <span class="label">Por:</span>
                            <span class="price">{{campaign.end_value | currency}}</span>
                        </p>
                        <p class="period" *ngIf="campaign?.start_date && campaign?.end_date">De: {{campaign.start_date | date: 'dd.MM.yyyy'}} à {{campaign.end_date | date: 'dd.MM.yyyy'}}</p>
                    </ng-container>
                    <ng-container *ngIf="campaign?.start_value && campaign?.end_value && campaign?.start_value === campaign?.end_value">
                        <p class="original-price bigger-text">A partir de:</p>
                        <p class="discounted-price">
                            <span class="price">{{campaign.start_value | currency}}</span>
                        </p>
                        <p class="period">De: {{campaign.start_date | date: 'dd.MM.yyyy'}} à {{campaign.end_date | date: 'dd.MM.yyyy'}}</p>
                    </ng-container>
                </div>

                <div class="counter-container" *ngIf="showTimer">
                    <my-portal-counter [type]="true" [date]="campaign.expiration_at"></my-portal-counter>
                </div>

                <div class="footer-actions">
                    <a [href]="campaign.link" [target]="campaign.type_link.id === 2 ? '_blank' : 'self'" class="redirect-action">{{campaign?.text_button}}</a>
                    <button type="button" class="close-action" (click)="closeCard()">Não tenho interesse</button>
                </div>
        </div>
    </div>
</ng-container>
