import {Injectable} from '@angular/core';
import {Product} from '../interface/product';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {AppDataService} from './app-data.service';
import {DocumentService} from './document.service';
import { InitializerService } from './initializer.service';
import { BudgetRoot } from '../../@types/budget-data';
import { BudgetPayloadRoot } from '../../@types/budget-payload';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    items: any = {
        hotels: {},
        count: 0,
        total: 0
    };
    uuid = this.documentService.getWindow()?.localStorage.getItem('uuid');

    private cartItems = new BehaviorSubject(this.items);
    currentDataCartItems = this.cartItems.asObservable();

    private cartFinishItems = new BehaviorSubject(null);
    currentDataCartFinishItems = this.cartFinishItems.asObservable();

    private accommodationsInCart = new BehaviorSubject(null);
    currentAccommodationsInCart = this.accommodationsInCart.asObservable();

    private cardPaymentDetails = new BehaviorSubject<any>(null);
    currentDataCardPaymentDetails = this.cardPaymentDetails.asObservable();

    private cardPaymentDetailsFormatted = new BehaviorSubject<any>(null);
    currentDataCardPaymentDetailsFormatted = this.cardPaymentDetailsFormatted.asObservable();

    private selectedParcelDetail = new BehaviorSubject<any>(null);
    currentSelectedParcelDetail = this.selectedParcelDetail.asObservable();

    private deleteCartItemSubcription = new BehaviorSubject(null);
    currentDeleteCartItemSubcription = this.deleteCartItemSubcription.asObservable();

    private cartRequestStatus = new BehaviorSubject<any>({});
    currentCartRequestStatus = this.cartRequestStatus.asObservable();

    constructor(
        private http: HttpClient,
        private dataService: AppDataService,
        private documentService: DocumentService,
        private initializerService: InitializerService,
    ) {}

    createCart(): void {
        this.documentService.getWindow()?.localStorage.setItem('cart', JSON.stringify(this.items));
    }

    addToCart(product: Product, type: string, personId: number, hotelName: any, channel: any): void {
        if (this.items[type][personId]) {
            this.items[type][personId].items.push(product);
        } else {
            this.items[type][personId] = {
                canal_id: (channel) ? channel : null,
                name: hotelName,
                id: personId,
                items: [],
                total: 0
            };
            this.items[type][personId].items.push(product);
        }
        this.items[type][personId].total = this.calcTotalHotel(this.items[type][personId].items);
        this.items[type][personId].discount = 0;
        this.items[type][personId].discountName = null;
        this.items[type][personId].additional = 0;

        this.items.count = this.counter(true);
        this.items.total = this.counter(false);

        this.documentService.getWindow()?.localStorage.setItem('cart', JSON.stringify(this.items));
        this.changeDataCartItem();
        if (type === 'hotels') {
            // if (this.items.count === 1) {
            //     this.saveDataCart(this.items).subscribe();
            // } else {
            //     this.updateDataCart(this.items).subscribe();
            // }
        }
    }

    getItems(): any[] {
        const sessionCart: any = this.documentService.getWindow()?.localStorage.getItem('cart');
        if (!sessionCart) {
            this.getItemsShoppingCart();
        } else {
            this.items = (this.documentService.getWindow()?.localStorage.getItem('cart')) ? JSON.parse(sessionCart) : this.items;
            this.items.count = this.counter(true);
            this.items.total = this.counter(false);
        }
        this.changeDataCartItem();
        return this.items;
    }

    removeItemCart(type: string, index: any, personId: number, accommodationInCart?: any): void {
        this.items[type][personId].items.splice(index, 1);
        this.items.count = this.counter(true);
        this.items.total = this.counter(false);

        this.initializerService.changeUncheckPolicies({ removeAt: index, personId, status: 'unchecked' })

        this.items[type][personId].total = this.calcTotalHotel(this.items[type][personId].items);

        if (this.items.count === 0) {
            this.items = {
                hotels: {},
                count: 0,
                total: 0
            };
        }

        if (accommodationInCart) {
            for (const accommodationKey in accommodationInCart) {
                if (this.items[type][personId]?.items) {
                    let keyExists = this.items[type][personId].items.some((key: any) => ('chosenAccommodation' in key) && key.chosenAccommodation === parseInt(accommodationKey));
                    accommodationInCart[accommodationKey] = keyExists;
                }
                if (!this.items[type][personId]?.items) {
                    accommodationInCart[accommodationKey] = false;
                }
            }
            this.handleAccommodationsInCart(accommodationInCart);
        }

        this.documentService.getWindow()?.localStorage.setItem('cart', JSON.stringify(this.items));
        // this.updateDataCart(this.items).subscribe();
        this.changeDataCartItem();
        this.deleteCartItemSubcription.next(this.items);
    }

    clearCart(): any[] {
        this.items = {
            hotels: {},
            count: 0,
            total: 0
        };
        this.changeDataCartItem(this.items);
        return this.items;
    }

    getItemsShoppingCart(): void {
        this.cartRequestStatus.next({ isLoading: true });

        this.getSaveDataCart().subscribe((response: any) => {
            if (Object.values(response.data).length) {
                this.items = response.data;
                this.items.count = this.counter(true, true);
                this.items.total = this.counter(false, true);
                this.createCart();
                this.changeDataCartItem();
                this.cartRequestStatus.next({ isLoading: false });
            }
        }, (error :any) => {
            this.cartRequestStatus.next({ isLoading: false, error: true });
        });
    }

    saveDataCart(product: Product): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}sales/shopping-cart`, product, {
            headers: objParams
        });
    }

    getSaveDataCart(): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.get(`${environment.API_ENDPOINT}sales/shopping-cart`, {
            headers: objParams
        });
    }

    updateDataCart(product: Product): Observable<object> {

        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.put(`${environment.API_ENDPOINT}sales/shopping-cart`, product, {
            headers: objParams
        });

    }

    changeDataCartItem(items?: any): void {
        this.items = (items) ? items : this.items;

        this.items.count = this.counter(true);
        this.items.total = this.counter(false);
        this.documentService.getWindow()?.localStorage.setItem('cart', JSON.stringify(this.items));
        this.cartItems.next(this.items);
    }

    changeSelectedParcel(parcelData: any): void {
        this.selectedParcelDetail.next(parcelData);
    }

    setItemsFinishSale(items: any): void {
        this.cartFinishItems.next(items);
    }

    counter(flag: boolean, restore?: boolean): number {
        let count = 0;
        for (const item of Object.keys(this.items)) {
            if (item !== 'count') {
                if (item !== 'total') {
                    for (const hotel of Object.keys(this.items[item])) {
                        if (flag) {
                            count += this.items[item][hotel].items.length;
                        } else {
                            if (this.items[item][hotel].items.length) {
                                if (restore) {
                                    this.items[item][hotel].recuperado = true;
                                }
                                for (const innerItem of this.items[item][hotel].items) {
                                    let markup = 0;
                                    if (innerItem.markup) {
                                        markup = (innerItem.markup) ? innerItem.markup.value : 0;
                                        if (!innerItem.markup.positive) {
                                            markup = -Math.abs(markup);
                                        }
                                    }
                                    innerItem.preco = (
                                        (
                                            (
                                                (
                                                    innerItem.diarias -
                                                    ((innerItem.cupom) ? (innerItem.cupom.applied) ? innerItem.cupom.discount : 0 : 0)
                                                )
                                                + ((innerItem.taxas) ? innerItem.taxas.total : 0)
                                            ) + markup
                                        ) + innerItem.suplementos.total
                                    );
                                }
                            }
                        }

                        this.items[item][hotel].total = (
                            this.calcTotalHotel(this.items[item][hotel].items) - (
                                (this.items[item][hotel].discount) ?
                                    this.items[item][hotel].discount : 0) -
                            ((this.items[item][hotel].desconto_recuperacao) ?
                                    this.items[item][hotel].desconto_recuperacao : 0)
                        ) + ((this.items[item][hotel].additional) ? this.items[item][hotel].additional : 0);

                        if (!flag) {
                            count += this.items[item][hotel].total;
                        }
                    }
                }
            }
        }
        return count;
    }

    calcTotalHotel(items: any): number {
        let total = 0;
        for (const item of items) {
            total += item.preco;
        }
        return total;
    }

    handleAccommodationsInCart(objAccommodations: any): void {
        this.accommodationsInCart.next(objAccommodations);
    }

    setCardPaymentDetails(cardDetails: any): void {
        if (cardDetails) {
            const currentData = this.cardPaymentDetails.value;

            const newData = {
                establishmentId: cardDetails.establishmentId,
                [cardDetails.establishmentId]: cardDetails.paymentForm,
            }

            this.cardPaymentDetails.next(newData);
        } else {
            this.cardPaymentDetails.next(null);
        }
    }

    setCardPaymentDetailsFormatted(cardDetails: any): void {
        if (cardDetails) {
            this.cardPaymentDetailsFormatted.next(cardDetails);
        } else {
            this.cardPaymentDetailsFormatted.next(null);
        }
    }

    createBudgetDataPayload(budgetData: BudgetRoot): any {
        const budgetPayload: BudgetPayloadRoot = {
            hotels: {}
        };

        for (const key in budgetData.data.hotels) {
            budgetPayload.hotels[key] = {
                payment: {
                    form_id: 123,
                    payment_id: 123
                },
                items: budgetData.data.hotels[key].hotel.carrinhos.map((item) => {
                    return {
                        sale_id: 1122830,
                        price: 100,
                        cancelation_id: 504,
                        installments: null,
                    };
                }),
            }
        }

        return {
        }
    }

    downloadVoucher(uuid: string, politicalDepositId: number): Observable<Blob> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.get(`${environment.API_ENDPOINT}voucher?uuid=${uuid}&politicalDepositId=${politicalDepositId}`, { responseType: 'blob', headers: objParams });
    }
}
