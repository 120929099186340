import { Component, Input, OnInit } from '@angular/core';
import { get } from 'http';
import { AppDataService } from '../../services/app-data.service';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'projects/new-portal/src/environments/environment';
import { InitializerService } from '../../services/initializer.service';
import { LeadBannerComponent } from './components/lead-banner/lead-banner.component';
import { DocumentService } from '../../services/document.service';

@Component({
    selector: 'my-portal-campaigns',
    templateUrl: './campaigns.component.html',
    styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit {
    @Input() leadData: any[] = [];
    public isLoadingLeads = true;

    private isSafari: any = this.initializerService.isSafari();
    public imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    public imgServerUrlEnd: any;

    constructor(
        private appData: AppDataService,
        private dialog: MatDialog,
        private initializerService: InitializerService,
        private documentService: DocumentService
    ) {}

    ngOnInit(): void {
        this.getLeads();

        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
    }

    private getLeads() {
        if (!this.leadData?.length) {
            this.appData
                .getLeads()
                .pipe(finalize(() => (this.isLoadingLeads = false)))
                .subscribe((response: any) => {
                    this.leadData = response.data;
                });
        }
    }

}
