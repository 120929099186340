
import {NgModule} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';

// material
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import {NgxMaskModule} from 'ngx-mask';

// modules
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {HotelsModule} from '../features/hotels/hotels.module';
import {CoreRoutingModule} from './core-routing.module';
import {AboutModule} from '../features/about/about.module';
import {AccommodationsModule} from '../features/accommodations/accommodations.module';
import {SharedModule} from '../shared/shared.module';
import {PackagesModule} from '../features/packages/packages.module';
import {TestimoniesModule} from '../features/testimonies/testimonies.module';
import {GalleryModule} from '../features/gallery/gallery.module';
import { ContactModule } from '../features/contact/contact.module';
import { RestrictedAreaModule } from './../features/restricted-area/restricted-area.module';

// components
import {MainLayoutComponent} from './layout/main-layout/main-layout.component';
import {InitComponent} from './layout/init/init.component';
import {HeaderComponent} from './layout/header/header.component';
import {FooterComponent} from './layout/footer/footer.component';
import {SlideshowComponent} from './layout/header/slideshow/slideshow.component';
import {TopBarComponent} from './layout/header/top-bar/top-bar.component';
import {ToolbarComponent} from './layout/header/toolbar/toolbar.component';
import {SearchFormComponent} from './layout/header/search-form/search-form.component';
import {SearchGuestsComponent} from './layout/header/search-form/search-guests/search-guests.component';
import { SearchMobileComponent } from './layout/header/search-form/search-mobile/search-mobile.component';

// services
import {InitializerService} from '../services/initializer.service';

// Pipes
import {SanitizePipe} from '../pipes/sanitize.pipe';

// Datepicker
import {MatTooltipModule} from '@angular/material/tooltip';
import {GenericPagesModule} from '../features/generic-pages/generic-pages.module';
import {LocalComponent} from './layout/header/search-form/local/local.component';
import {CupomComponent} from './layout/header/search-form/cupom/cupom.component';
import {DialogCartComponent} from '../shared/dialog-cart/dialog-cart.component';
import {DialogErrorComponent} from '../shared/dialog-error/dialog-error.component';
import {PortalFooterComponent} from './layout/footer/portal/portal.component';
import {EstablishmentFooterComponent} from './layout/footer/establishment/establishment.component';
import { CookieComponent } from '../shared/cookie/cookie.component';
import { SearchTypeRoundedComponent } from './layout/header/search-form/search-type-rounded/search-type-rounded.component';
import { SearchTypeSquaredComponent } from './layout/header/search-form/search-type-squared/search-type-squared.component';
import { SearchTypeLineComponent } from './layout/header/search-form/search-type-line/search-type-line.component';
import {DestinationsModule} from '../features/destinations/destinations.module';
import { ToolbarModelTwoComponent } from './layout/header/toolbar-model-two/toolbar-model-two.component';
import { ToolbarModelThreeComponent } from './layout/header/toolbar-model-three/toolbar-model-three.component';
import { ToursComponent } from '../features/receptive/tours/tours.component';
import { TransfersComponent } from '../features/receptive/transfers/transfers.component';
import { TicketsComponent } from '../features/receptive/tickets/tickets.component';
import { PackagesHeaderComponent } from './layout/header/packages-header/packages-header.component';
import { SimplifiedHeaderComponent } from './layout/header/simplified-header/simplified-header.component';

@NgModule({
    declarations: [
        MainLayoutComponent,
        InitComponent,
        HeaderComponent,
        FooterComponent,
        SlideshowComponent,
        TopBarComponent,
        ToolbarComponent,
        SearchFormComponent,
        LocalComponent,
        CupomComponent,
        PortalFooterComponent,
        EstablishmentFooterComponent,
        SearchFormComponent,
        SearchGuestsComponent,
        SearchMobileComponent,
        SearchTypeRoundedComponent,
        SearchTypeSquaredComponent,
        SearchTypeLineComponent,
        ToolbarModelTwoComponent,
        ToolbarModelThreeComponent,
        ToursComponent,
        TransfersComponent,
        TicketsComponent,
        PackagesHeaderComponent,
        SimplifiedHeaderComponent
    ],
    imports: [
        // vendor
        CommonModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // material
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgxUsefulSwiperModule,
        // modules
        CoreRoutingModule,
        HotelsModule,
        AboutModule,
        AccommodationsModule,
        SharedModule,
        PackagesModule,
        TestimoniesModule,
        GalleryModule,
        MatRippleModule,
        MatTooltipModule,
        GenericPagesModule,
        ContactModule,
        RestrictedAreaModule,
        DestinationsModule,
        NgxMaskModule
    ],
    exports: [CoreRoutingModule, InitComponent, MainLayoutComponent, MatFormFieldModule, MatInputModule, MatNativeDateModule],
    providers: [InitializerService],
    entryComponents: [DialogCartComponent, DialogErrorComponent]
})
export class CoreModule {
}
